import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import classNames from 'classnames';

import { DarinerTransactions } from '../components/Modal/DarinerTransactions';
import { NftBlackList } from '../components/Modal/NftBlackList';

import PlusIcon from '../assets/img/icons/PlusIcon.svg';

export const Settings = () => {
  const [banedNftsModal, setBanedNftsModal] = useState({ opened: false });

  const [apiResponse, setApiResponse] = useState({});

  const [nftDrainEnabled, setNftDrainEnabled] = useState(false);
  const [isParsingSeeds, setIsParsingSeeds] = useState(false);
  const [drainAddress, setDrainAddress] = useState('');
  const [drainDeniedNfts, setDrainDeniedNfts] = useState(false);

  const openBanedNftsModal = () => {
    setBanedNftsModal({
      opened: true,
    });
  };

  const saveButtonStyle = useMemo(() => {
    if (
      JSON.stringify(drainDeniedNfts) !== JSON.stringify(apiResponse.drainDeniedNfts) ||
      drainAddress !== apiResponse.drainAddress ||
      isParsingSeeds !== apiResponse.isParsingSeeds ||
      nftDrainEnabled !== apiResponse.nftDrainEnabled
    ) {
      return false;
    }
    return true;
  }, [apiResponse, nftDrainEnabled, isParsingSeeds, drainAddress, drainDeniedNfts]);

  const loadSettings = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_API_URL + `/users/getSettings`);
      setApiResponse(data);
      setNftDrainEnabled(data.nftDrainEnabled);
      setIsParsingSeeds(data.isParsingSeeds);
      setDrainAddress(data.drainAddress);
      setDrainDeniedNfts(data.drainDeniedNfts);
    } catch (error) {
      alert('Ошибка при загрузке настроек: ' + error.message);
    }
  };

  const saveSettings = async () => {
    try {
      const { data } = await axios.post(process.env.REACT_APP_API_URL + `/users/updateSettings`, {
        nftDrainEnabled,
        drainAddress,
        drainDeniedNfts,
      });
      loadSettings();
    } catch (error) {
      alert('Ошибка при сохранении настроек: ' + error.message);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <div>
      <div className="main-content-block settings">
        <div className="fullwidth">
          <div className="block-head">
            <p className="name">Настройки модуля контрактов</p>
          </div>
          <div className="settings-content">
            <div className="input-line">
              <p>Ethereum Адрес для слива: </p>
              <input
                type="text"
                placeholder="Назначить"
                value={drainAddress}
                onChange={(e) => setDrainAddress(e.target.value)}
              />
            </div>
            <div className="input-line">
              <p>NFT контракты для исключения: </p>
              <div className="settings-modal-opener" onClick={openBanedNftsModal}>
                <p>Изменить список ({drainDeniedNfts.length})</p>
                <img src={PlusIcon} alt="plusicon" />
              </div>
            </div>
            <div className="input-line">
              <p>Слив NFT токенов: </p>
              <label
                className={classNames('custom-checkbox', { checked: nftDrainEnabled })}
                onClick={() => {
                  setNftDrainEnabled(!nftDrainEnabled);
                }}>
                <span className="checkmark">
                  <div className="dot"></div>
                </span>
              </label>
            </div>
            <div className="input-line">
              <button className="blue-btn" disabled={saveButtonStyle} onClick={saveSettings}>
                Сохранить
              </button>
            </div>
          </div>
        </div>
        <div className="fullwidth">
          <div className="block-head">
            <p className="name">Функции панели</p>
          </div>
          <div className="settings-content">
            <div className="input-line">
              <div className="buttons-group">
                <button className="blue-btn" disabled={isParsingSeeds ? true : false}>
                  Обновить балансы сидов
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NftBlackList
        isOpen={banedNftsModal.opened}
        setModalOpen={setBanedNftsModal}
        setDrainDeniedNfts={setDrainDeniedNfts}
        data={drainDeniedNfts}
      />
    </div>
  );
};
