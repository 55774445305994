import React, { useRef, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  registerables as registerablesJS,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(...registerablesJS);

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler, {
  id: 'uniqueid5',
  afterDraw: function (chart) {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0];
      const ctx = chart.ctx;
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#808080';
      ctx.stroke();
      ctx.restore();

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x - 3, topY);
      ctx.lineTo(x + 3, topY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#808080';
      ctx.stroke();
      ctx.restore();

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x - 3, bottomY);
      ctx.lineTo(x + 3, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#808080';
      ctx.stroke();
      ctx.restore();
    }
  },
});

const labels = ['Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 500000 })),
      lineTension: 0.3,
      borderWidth: 3,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#242426',
      pointHoverBorderWidth: 3,
      pointHoverBorderColor: '#ffffff',
      fill: true,
    },
  ],
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      // enabled: false,
      mode: 'index',
      intersect: false,
      displayColors: false,
      bodyFont: {
        size: 10,
        family: "'Montserrat', sans-serif",
        weight: 600,
      },
      callbacks: {
        title: function (tooltipItems, data) {
          return '';
        },
        label: function (tooltipItem, data) {
          return tooltipItem.raw + '$'; //Some IDEs throw an error if you don't cast to a string
        },
      },
    },
  },
  hover: {
    mode: 'index',
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: '#242426',
      borderWidth: 11,
      borderColor: '#ffffff',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          size: 12,
          family: "'Montserrat', sans-serif",
          weight: 600,
          lineHeight: 2.5,
        },
        padding: 25,
        color: '#5B5B5B',
        callback: (value, index, ticks) => {
          return value + '$';
        },
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        font: {
          size: 12,
          family: "'Montserrat', sans-serif",
          weight: 600,
          lineHeight: 2.5,
        },
        color: '#5B5B5B',
        align: 'center',
      },
      grid: {
        drawBorder: false,
        color: '#2A2A2D',
      },
    },
  },
};

function createBorderGradient(ctx, area) {
  const colorStart = 'rgb(68,74,218)';
  const colorEnd = 'rgb(75,181,225)';

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

function createBackgroundGradient(ctx, area) {
  const colorStart = 'rgba(75,181,255, 0.05)';
  const colorMid = 'rgba(33,33,35, 0.07)';
  const colorEnd = 'rgba(33,33,35, 0.1)';

  const gradient = ctx.createLinearGradient(0, area.top, 0, 500);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

export const IncomeChart = ({ incomes }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    // const chartData = {
    //   ...data,
    //   datasets: data.datasets.map((dataset) => ({
    //     ...dataset,
    //     borderColor: createBorderGradient(chart.ctx, chart.chartArea),
    //     backgroundColor: createBackgroundGradient(chart.ctx, chart.chartArea),
    //   })),
    // };
    const chartData = {
      labels: Object.keys(incomes),
      datasets: [
        {
          label: 'Dataset 1',
          data: Object.keys(incomes).map((key) => incomes[key]),
          lineTension: 0.3,
          borderWidth: 3,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#242426',
          pointHoverBorderWidth: 3,
          pointHoverBorderColor: '#ffffff',
          fill: true,
          borderColor: createBorderGradient(chart.ctx, chart.chartArea),
          backgroundColor: createBackgroundGradient(chart.ctx, chart.chartArea),
        },
      ],
    };

    setChartData(chartData);
  }, []);
  return <Chart ref={chartRef} type="line" data={chartData} options={options} />;
};
