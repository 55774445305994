import React from 'react';
import Modal from 'react-modal';

import { formatDate } from '../../utils';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/modal-close.svg';

const chainIds = {
  1: 'ETH',
  56: 'BSC',
  137: 'MATIC',
  250: 'FTM',
  43114: 'AVAX',
  42161: 'ARB',
  59144: 'LINEA',
  8453: 'BASE',
  10: 'OP',
};

const chainExplorers = {
  1: 'https://etherscan.io/',
  56: 'https://bscscan.com/',
  137: 'https://polygonscan.com/',
  250: 'https://ftmscan.com/',
  43114: 'https://snowtrace.io/',
  42161: 'https://arbiscan.io/',
  59144: 'https://lineascan.build/',
  8453: 'https://basescan.org/',
  10: 'https://optimistic.etherscan.io/',
};

export const DarinerTransactions = ({ isOpen, setModalOpen, data }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModalOpen({ opened: false, data: {} })}
      className="Modal transactions"
      overlayClassName="Overlay">
      <div className="modalHeader">
        <div className="content">
          <div className="tab active">
            <span>Транзакции</span>
          </div>
        </div>
        <div className="close" onClick={() => setModalOpen({ opened: false, data: {} })}>
          <CloseIcon />
        </div>
      </div>
      <div className="modalBody">
        <div className="transactions-table">
          <div className="head">
            <div className="cell" style={{ width: '40%' }}>
              Транзакция:
            </div>
            <div className="cell" style={{ width: '15%' }}>
              Сумма:
            </div>
            <div className="cell" style={{ width: '17%' }}>
              Коин:
            </div>
            <div className="cell" style={{ width: '13%' }}>
              Сеть:
            </div>
            <div className="cell" style={{ width: '15%' }}>
              Дата:
            </div>
          </div>
          <div className="rows">
            {data && data.length > 0
              ? data.map((el) => (
                  <div className="row" key={el.id}>
                    <a
                      className="cell blue"
                      style={{ width: '38%' }}
                      href={`${chainExplorers[el.chainId]}tx/${el.txHash}`}
                      target="_blank"
                      rel="noreferrer">
                      {el.txHash.substring(0, 30) + '...'}
                    </a>
                    <div className="cell green" style={{ width: '12%' }}>
                      $ {el.summ.toLocaleString('en-US')}
                    </div>
                    <div className="cell" style={{ width: '15%' }}>
                      {el.desc}
                    </div>
                    <div className="cell" style={{ width: '10%' }}>
                      {chainIds[el.chainId]}
                    </div>
                    <div className="cell" style={{ width: '20%' }}>
                      {formatDate(el.createdAt)}
                    </div>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
    </Modal>
  );
};
