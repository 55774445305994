import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { ReactComponent as UserAvatar } from '../assets/img/user-avatar.svg';
import { ReactComponent as HeaderLogo } from '../assets/img/header-logo.svg';

import { ReactComponent as WalletIcon } from '../assets/img/icons/wallet.svg';
import { ReactComponent as BitcoinIcon } from '../assets/img/icons/bitcoin.svg';
import { ReactComponent as SettingsIcon } from '../assets/img/icons/settings.svg';
import { ReactComponent as LogsIcon } from '../assets/img/icons/logs.svg';

export const Header = () => {
  return (
    <div className="header">
      <div className="navigation">
        <Link to="/">
          <HeaderLogo className="logo" />
        </Link>
        <div className="menu">
          <NavLink to="/seeds" className={(e) => (e.isActive ? 'item active' : 'item')}>
            <WalletIcon />
            <span>Кошельки</span>
          </NavLink>
          <NavLink to="/drainer" className={(e) => (e.isActive ? 'item active' : 'item')}>
            <BitcoinIcon />
            <span>Контракты</span>
          </NavLink>
          <NavLink to="/logs" className={(e) => (e.isActive ? 'item active' : 'item')}>
            <LogsIcon />
            <span>Авторизации</span>
          </NavLink>
          <NavLink to="/settings" className={(e) => (e.isActive ? 'item active' : 'item')}>
            <SettingsIcon />
            <span>Настройки</span>
          </NavLink>
        </div>
      </div>
      <div className="profile">
        <span>BO$$</span>
        <UserAvatar />
      </div>
    </div>
  );
};
