import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '../assets/img/icons/arrow-up.svg';

export const Sort = ({ title, items, onChange }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const sortRef = useRef(null);

  useEffect(() => {
    setSelected(items[0].title);
  }, [items]);

  const onClickItem = (i) => {
    setOpen(false);
    if (items[i].title !== selected) {
      setSelected(items[i].title);
      onChange(items[i].value);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const path = event.path || event.composedPath();
      if (sortRef.current && !path.includes(sortRef.current)) {
        setOpen(false);
      }
    };
    document.body.addEventListener('click', handleClickOutside);
    return () => document.body.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div
      ref={sortRef}
      className={classNames('sort', {
        opened: open,
      })}>
      <p>{title}: </p>
      <span onClick={() => setOpen(!open)}>
        {selected} <ArrowIcon />
      </span>
      <div className="sortList">
        {items.map((item, i) => (
          <span key={i} onClick={() => onClickItem(i)}>
            {item.title}
          </span>
        ))}
      </div>
    </div>
  );
};
