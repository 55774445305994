import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Moment from 'moment';
import className from 'classnames';

import { ReactComponent as CloseIcon } from '../../assets/img/icons/modal-close.svg';

export const SeedBalance = ({ isOpen, setModalOpen, data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ['btc', 'eth', 'xrp', 'sol', 'trx'];

  const getObjectProp = () => {
    return tabs[activeTab] + '_val';
  };

  const getExplorer = (address) => {
    const coin = tabs[activeTab];
    if (coin === 'btc') {
      return `https://blockchair.com/bitcoin/address/${address}`;
    }
    if (coin === 'eth') {
      return `https://etherscan.io/address/${address}`;
    }
    if (coin === 'xrp') {
      return `https://blockchair.com/ripple/account/${address}`;
    }
    if (coin === 'sol') {
      return `https://solscan.io/account/${address}`;
    }
    if (coin === 'trx') {
      return `https://tronscan.org/#/address/${address}`;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModalOpen({ opened: false, data: {} })}
      onAfterClose={() => setActiveTab(0)}
      className="Modal"
      overlayClassName="Overlay">
      <div className="modalHeader">
        <div className="content">
          {tabs.map((tab, i) => (
            <div
              className={className('tab', { active: i === activeTab })}
              key={tab + i}
              onClick={() => setActiveTab(i)}>
              <span>{tab.toUpperCase()}</span>
              {data[tab + '_val'] && data[tab + '_val'].total > 0 && (
                <div className="badge">${data[tab + '_val'].total}</div>
              )}
            </div>
          ))}
        </div>
        <div className="close" onClick={() => setModalOpen({ opened: false, data: {} })}>
          <CloseIcon />
        </div>
      </div>
      <div className="modalBody">
        {data[getObjectProp()] &&
          data[getObjectProp()].derives.map((derive, i) => (
            <div className="balanceRow" key={derive.address + i}>
              <div className="badge">{derive.derivePath}</div>
              <a
                className="badge"
                href={getExplorer(derive.address)}
                target="_blank"
                rel="noreferrer">
                {derive.address}
              </a>
              <div className={className('badge', { error: derive.error })}>
                {derive.balance ? derive.balance : 0}$
              </div>
              <div className={className('badge', { error: derive.error })}>
                {derive.txCount ? derive.txCount : 0}
              </div>
            </div>
          ))}

        <p className="updateDate">{Moment(data.updatedAt).format('DD.MM.YYYY HH:mm')}</p>
      </div>
    </Modal>
  );
};
