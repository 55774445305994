import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';

import PlusIcon from '../../assets/img/icons/PlusIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/modal-close.svg';

export const NftBlackList = ({ isOpen, setModalOpen, setDrainDeniedNfts, data }) => {
  const [newAddress, setNewAddress] = useState('');

  const addAddress = useCallback(() => {
    const isExist = data.findIndex((el) => el.toLowerCase() === newAddress.toLowerCase());
    if (newAddress.trim() !== '' && isExist === -1) {
      setDrainDeniedNfts([newAddress, ...data]);
      setNewAddress('');
    } else {
      setNewAddress('');
    }
  }, [data, newAddress, setNewAddress, setDrainDeniedNfts]);

  const removeAddress = useCallback(
    (index) => {
      setDrainDeniedNfts(data.filter((_, i) => i !== index));
    },
    [setDrainDeniedNfts, data],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModalOpen({ opened: false, data: {} })}
      className="Modal nftblacklist"
      overlayClassName="Overlay">
      <div className="modalHeader">
        <div className="content">
          <div className="tab active">
            <span>Игнорируемые NFT</span>
          </div>
        </div>
        <div className="close" onClick={() => setModalOpen({ opened: false })}>
          <CloseIcon />
        </div>
      </div>
      <div className="modalBody">
        <div className="blacklist-modal-input">
          <input
            type="text"
            placeholder="Адрес коллекции"
            value={newAddress}
            onChange={(e) => setNewAddress(e.target.value)}
          />
          <button onClick={addAddress}>
            <img src={PlusIcon} alt="PlusIcon" />
          </button>
        </div>
        {data.length > 0 &&
          data.map((el, i) => (
            <div className="blacklist-modal-item" key={el + i}>
              <div className="address">{el}</div>
              <div className="remove" onClick={() => removeAddress(i)}>
                <CloseIcon />
              </div>
            </div>
          ))}
      </div>
    </Modal>
  );
};
