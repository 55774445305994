import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import className from 'classnames';

import { getUserProfile } from '../redux/user/userActions';
import { ReactComponent as HeaderLogo } from '../assets/img/header-logo.svg';

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const { register, handleSubmit } = useForm();

  const { userInfo } = useSelector((state) => state.user);
  useEffect(() => {
    if (userInfo) navigate('/');
  }, [userInfo]);

  const loginUser = async (formData) => {
    try {
      await axios.post(process.env.REACT_APP_API_URL + '/auth/login', formData);
      dispatch(getUserProfile());
      navigate('/');
    } catch (error) {
      setError(true);
    }
  };

  return (
    <div className="loginContainer">
      <div className="logo-block">
        <div className="logo-wrap">
          <HeaderLogo />
        </div>
      </div>
      <div className="login-block-wrapper">
        <div className="background"></div>
        <div className="form">
          <input type="text" placeholder="ЛОГИН" {...register('username')} />
          <input type="password" placeholder="ПАРОЛЬ" {...register('password')} />
          <button onClick={handleSubmit(loginUser)}>Авторизоваться</button>
          <p className={className('error', { show: error })}>Неверный Логин/Пароль</p>
        </div>
      </div>
    </div>
  );
};
