import React, { useRef, useEffect, Fragment, useState } from 'react';
import MapComponent, { Marker } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import jsonLocations from './locations.json';

const style = {
  id: '43f36e14-e3f5-43c1-84c0-50a9c80dc5c7',
  name: 'MapLibre',
  zoom: 0.8619833357855968,
  pitch: 0,
  center: [17.65431710431244, 32.954120326746775],
  glyphs: 'https://fonts.openmaptiles.org/{fontstack}/{range}.pbf',
  layers: [
    {
      id: 'background',
      type: 'background',
      paint: {
        'background-color': '#212123',
      },
      filter: ['all'],
      layout: {
        visibility: 'visible',
      },
      maxzoom: 24,
    },
    {
      id: 'countries-fill',
      type: 'fill',
      paint: {
        'fill-color': [
          'match',
          ['get', 'ADM0_A3'],
          [
            'ARM',
            'ATG',
            'AUS',
            'BTN',
            'CAN',
            'COG',
            'CZE',
            'GHA',
            'GIN',
            'HTI',
            'ISL',
            'JOR',
            'KHM',
            'KOR',
            'LVA',
            'MLT',
            'MNE',
            'MOZ',
            'PER',
            'SAH',
            'SGP',
            'SLV',
            'SOM',
            'TJK',
            'TUV',
            'UKR',
            'WSM',
          ],
          '#2E2E31',
          [
            'AZE',
            'BGD',
            'CHL',
            'CMR',
            'CSI',
            'DEU',
            'DJI',
            'GUY',
            'HUN',
            'IOA',
            'JAM',
            'LBN',
            'LBY',
            'LSO',
            'MDG',
            'MKD',
            'MNG',
            'MRT',
            'NIU',
            'NZL',
            'PCN',
            'PYF',
            'SAU',
            'SHN',
            'STP',
            'TTO',
            'UGA',
            'UZB',
            'ZMB',
          ],
          '#2E2E31',
          [
            'AGO',
            'ASM',
            'ATF',
            'BDI',
            'BFA',
            'BGR',
            'BLZ',
            'BRA',
            'CHN',
            'CRI',
            'ESP',
            'HKG',
            'HRV',
            'IDN',
            'IRN',
            'ISR',
            'KNA',
            'LBR',
            'LCA',
            'MAC',
            'MUS',
            'NOR',
            'PLW',
            'POL',
            'PRI',
            'SDN',
            'TUN',
            'UMI',
            'USA',
            'USG',
            'VIR',
            'VUT',
          ],
          '#2E2E31',
          [
            'ARE',
            'ARG',
            'BHS',
            'CIV',
            'CLP',
            'DMA',
            'ETH',
            'GAB',
            'GRD',
            'HMD',
            'IND',
            'IOT',
            'IRL',
            'IRQ',
            'ITA',
            'KOS',
            'LUX',
            'MEX',
            'NAM',
            'NER',
            'PHL',
            'PRT',
            'RUS',
            'SEN',
            'SUR',
            'TZA',
            'VAT',
          ],
          '#2E2E31',
          [
            'AUT',
            'BEL',
            'BHR',
            'BMU',
            'BRB',
            'CYN',
            'DZA',
            'EST',
            'FLK',
            'GMB',
            'GUM',
            'HND',
            'JEY',
            'KGZ',
            'LIE',
            'MAF',
            'MDA',
            'NGA',
            'NRU',
            'SLB',
            'SOL',
            'SRB',
            'SWZ',
            'THA',
            'TUR',
            'VEN',
            'VGB',
          ],
          '#2E2E31',
          [
            'AIA',
            'BIH',
            'BLM',
            'BRN',
            'CAF',
            'CHE',
            'COM',
            'CPV',
            'CUB',
            'ECU',
            'ESB',
            'FSM',
            'GAZ',
            'GBR',
            'GEO',
            'KEN',
            'LTU',
            'MAR',
            'MCO',
            'MDV',
            'NFK',
            'NPL',
            'PNG',
            'PRY',
            'QAT',
            'SLE',
            'SPM',
            'SYC',
            'TCA',
            'TKM',
            'TLS',
            'VNM',
            'WEB',
            'WSB',
            'YEM',
            'ZWE',
          ],
          '#2E2E31',
          [
            'ABW',
            'ALB',
            'AND',
            'ATC',
            'BOL',
            'COD',
            'CUW',
            'CYM',
            'CYP',
            'EGY',
            'FJI',
            'GGY',
            'IMN',
            'KAB',
            'KAZ',
            'KWT',
            'LAO',
            'MLI',
            'MNP',
            'MSR',
            'MYS',
            'NIC',
            'NLD',
            'PAK',
            'PAN',
            'PRK',
            'ROU',
            'SGS',
            'SVN',
            'SWE',
            'TGO',
            'TWN',
            'VCT',
            'ZAF',
          ],
          '#2E2E31',
          ['ATA', 'GRL'],
          '#2E2E31',
          '#2E2E31',
        ],
      },
      filter: ['all'],
      layout: {
        visibility: 'visible',
      },
      source: 'maplibre',
      maxzoom: 24,
      'source-layer': 'countries',
    },
    {
      id: 'countries-boundary',
      type: 'line',
      paint: {
        'line-color': '#212123',
        'line-width': 0.5,
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'maplibre',
      maxzoom: 24,
      'source-layer': 'countries',
    },
    {
      id: 'countries-label',
      type: 'symbol',
      paint: {
        'text-color': '#fff',
      },
      filter: ['all'],
      layout: {
        'text-font': ['Metropolis Regular'],
        'text-size': {
          stops: [
            [2, 10],
            [4, 12],
            [6, 16],
          ],
        },
        'text-field': {
          stops: [
            [2, '{ABBREV}'],
            [4, '{NAME}'],
          ],
        },
        visibility: 'visible',
        'text-max-width': 10,
        'text-transform': {
          stops: [
            [0, 'uppercase'],
            [2, 'none'],
          ],
        },
      },
      source: 'maplibre',
      maxzoom: 24,
      minzoom: 2,
      'source-layer': 'centroids',
    },
  ],
  bearing: 0,
  sources: {
    maplibre: {
      url: 'https://demotiles.maplibre.org/tiles/tiles.json',
      type: 'vector',
    },
  },
  version: 8,
  metadata: {
    'maptiler:copyright':
      'This style was generated on MapTiler Cloud. Usage is governed by the license terms in https://github.com/maplibre/demotiles/blob/gh-pages/LICENSE',
    'openmaptiles:version': '3.x',
  },
};

export const Map = ({ locations }) => {
  const mapRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const getCords = (location, num) => {
    return parseFloat(jsonLocations[location.toLowerCase()][num]);
  };
  useEffect(() => {}, []);
  return (
    <MapComponent
      mapLib={maplibregl}
      ref={mapRef}
      initialViewState={{
        longitude: 0,
        latitude: 40,
        zoom: 1.4,
      }}
      renderWorldCopies={false}
      mapStyle={style}
      minZoom={1.4}
      onLoad={() => setLoaded(true)}
      maxBounds={[
        [-200, -60],
        [200, 80],
      ]}>
      {loaded && locations && Object.keys(locations).length > 0 && (
        <Fragment>
          {Object.keys(locations).map((loc, i) => (
            <Marker
              longitude={getCords(loc, 1)}
              latitude={getCords(loc, 0)}
              anchor="bottom"
              key={loc + i}>
              <div className="mapMarker">{locations[loc]}</div>
            </Marker>
          ))}
        </Fragment>
      )}
    </MapComponent>
  );
};
