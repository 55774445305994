import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_API_URL + '/auth/profile');
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
