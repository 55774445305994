import Moment from 'moment';

export const getBalanceColor = (balance) => {
  if (balance >= 1000) {
    return 'green';
  }
  if (balance > 0) {
    return 'yellow';
  }
  return 'grey';
};

export const formatDate = (createdAt) => {
  const now = Moment(new Date());
  const end = Moment(createdAt);
  const duration = Moment.duration(now.diff(end));
  const hours = duration.asHours();
  if (hours < 1) {
    return Moment(createdAt).fromNow();
  }
  return Moment(createdAt).format('DD.MM.YYYY HH:mm');
};
