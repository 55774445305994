import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getUserProfile } from './redux/user/userActions';
import { MainLayout } from './layouts/MainLayout';
import { Dash } from './pages/Dash';
import { Seeds } from './pages/Seeds';
import { Logs } from './pages/Logs';
import { Login } from './pages/Login';
import Drainer from './pages/Drainer';
import { Settings } from './pages/Settings';

function App() {
  const { loading, error } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return (
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="" element={<Dash />} />
          <Route path="seeds" element={<Seeds />} />
          <Route path="logs" element={<Logs />} />
          <Route path="drainer" element={<Drainer />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    );
  }
}

export default App;
