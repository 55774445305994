import React, { useState, useEffect } from 'react';
import className from 'classnames';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import { IncomeChart } from '../components/IncomeChart';
import { Map } from '../components/Map';
import { ReactComponent as WalletIcon } from '../assets/img/icons/wallet.svg';
import { formatDate, getBalanceColor } from '../utils';

export const Dash = () => {
  const [dashData, setDashData] = useState({ loading: true });

  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_API_URL + `/seeds/dashboard`);
      setDashData({ ...data, loading: false });
    } catch (error) {
      alert('Ошибка при загрузке логов: ' + error.message);
    }
  };

  if (dashData.loading) {
    return <div></div>;
  }

  return (
    <div>
      <div className="main-content-block">
        <div className="left">
          <div className="block-head">
            <p className="name">Прибыль</p>
          </div>
          <div className="chart-wrap">
            <IncomeChart incomes={dashData.diagramData} />
          </div>
        </div>
        <div className="right">
          <div className="block-head">
            <p className="name">Новые кошельки</p>
            <NavLink to="/seeds" className="link">
              Смотреть
            </NavLink>
          </div>
          <div className="new-wallets">
            {dashData.lastSeeds.map((seed, i) => (
              <div className="wallet" key={seed.ethereum + i}>
                <div className="left">
                  <WalletIcon className="icon" />
                  <span>
                    <p className="date">{formatDate(seed.createdAt)}</p>
                    <p className="eth">{seed.ethereum.substring(0, 20) + '...'}</p>
                  </span>
                </div>
                <p className={className('summ', getBalanceColor(seed.balance))}>
                  {seed.balance.toLocaleString('en-US')}$
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="main-content-block">
        <div className="map-block">
          <div className="block-head">
            <p className="name">География логов</p>
          </div>
          <div className="map-wrap">
            <Map locations={dashData.mapData} />
          </div>
        </div>
      </div>
    </div>
  );
};
